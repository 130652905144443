<template>
  <div
    class="navbar-container p-0 d-flex content flex-row align-items-center text-center justify-content-center font-manrope multi-select-ui-v2"
    :class="{'multi-select-ui-v2 hide': !$store.state.shop.selectCount}"
  >
    <div class="multi-select-action products-count px-0 d-flex align-items-center justify-content-center font-weight-bold font-inter">
      <b-badge
        pill
        variant="light"
        class="text-primary mr-50"
      >
        {{ selectedProductsCount }}
      </b-badge>
      <div>
        {{ selectedProductsCount > 1 ? 'Products selected': 'Product selected' }}
      </div>
    </div>
    <div
      class="multi-select-action"
      @click="selectAll(true)"
    >
      Select all
    </div>
    <div
      class="multi-select-action"
      @click="selectAll(false)"
    >
      Deselect all
    </div>

    <template v-if="isRetailer">
      <template v-if="isShop">
        <div
          v-if="isOrderEnableInShop"
          class="multi-select-action d-flex align-items-center"
          @click="addToCartFromShop"
        >
          <feather-icon
            icon="ShoppingCartIcon"
            class="mr-50"
            size="18"
          />
          <div>
            Add to cart
          </div>
        </div>
        <div
          class="multi-select-action d-flex align-items-center"
          @click="downloadSelectedProducts"
        >
          <feather-icon
            icon="DownloadIcon"
            size="18"
            class="mr-50"
          />
          <div>
            Download products
          </div>
        </div>
        <div
          class="multi-select-action d-flex align-items-center"
          @click="selectOrCreateWishlist"
        >
          <feather-icon
            icon="HeartIcon"
            size="18"
            class="mr-50"
          />
          <div>
            Move to wishlist
          </div>
        </div>
        <div
          class="multi-select-action d-flex align-items-center text-danger"
          @click="removeWishlistFromShop"
        >
          <feather-icon
            icon="Trash2Icon"
            size="18"
            class="mr-50"
          />
          <div>
            Remove from wishlist
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-if="isOrderEnableInWishlist"
          class="multi-select-action d-flex align-items-center"
          @click="moveAllToCartFromWishlist"
        >
          <feather-icon
            icon="ShoppingCartIcon"
            class="mr-50"
            size="18"
          />
          <div>
            Add to cart
          </div>
        </div>
        <div
          class="multi-select-action d-flex align-items-center"
          @click="downloadSelectedProductsFromWishlist"
        >
          <feather-icon
            icon="DownloadIcon"
            size="18"
            class="mr-50"
          />
          <div>
            Download products
          </div>
        </div>
        <div
          class="multi-select-action"
          @click="removeAllFromWishList"
        >
          <b-spinner
            v-if="isRemovingFromWishlistProducts"
            small
          />
          <div
            v-else
            class="text-danger d-flex align-items-center"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="mr-50"
            />
            <div>
              Remove from wishlist
            </div>
          </div>
        </div>
      </template>
    </template>

    <template v-else>
      <div
        v-if="hasDirectRetailers"
        class="multi-select-action d-flex align-items-center"
        @click="addToOrder"
      >
        <feather-icon
          icon="StarIcon"
          size="18"
          class="mr-50"
        />
        <div>
          Add to order
        </div>
      </div>
      <div
        class="multi-select-action d-flex align-items-center"
        @click="downloadSelectedProducts"
      >
        <feather-icon
          icon="DownloadIcon"
          size="18"
          class="mr-50"
        />
        <div>
          Download products
        </div>
      </div>
      <div
        v-if="isShop"
        class="multi-select-action d-flex align-items-center"
        @click="selectOrCreateWishlist"
      >
        <feather-icon
          icon="BookmarkIcon"
          size="18"
          class="mr-50"
        />
        <div>
          Add to selection
        </div>
      </div>
      <div
        v-if="!isShop"
        class="multi-select-action"
        @click="removeAllFromWishList"
      >
        <b-spinner
          v-if="isRemovingFromWishlistProducts"
          small
        />
        <div
          v-else
          class="text-danger d-flex align-items-center"
        >
          <feather-icon
            icon="Trash2Icon"
            size="18"
            class="mr-50"
          />
          <div>
            Remove from selection
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
BSpinner,
BBadge,
} from 'bootstrap-vue'
import constants from '@/constants'
import { getWishlistTitle } from '@/@core/utils/utils'
import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { UPDATE_SELECT_COUNT, MULTI_SELECT_PAGES } from '@/store/modules/shop.module'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics from '@/@core/utils/analytics'

const { ROLES } = c
const { CONNECTION_REQUEST_STATUS, TRACKS } = constants
const { MULTI_SELECT_PRODUCTS } = TRACKS.ACTIONS

export default {
  components: {
    BSpinner,
    BBadge,
    FeatherIcon,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      getWishlistTitle,
      isRemovingFromWishlistProducts: false,
    }
  },
  computed: {
    isOrderEnableInShop() {
      if (this.$root.$refs.shop.isPremium) {
        const enableCheck = this.$root.$refs.shop.connectionRequestStatus === CONNECTION_REQUEST_STATUS.APPROVED
        return enableCheck
      }
      return true
    },
    isOrderEnableInWishlist() {
      if (this.$root.$refs.wishlist.isPremium) {
        const enableCheck = this.$root.$refs.wishlist.requestStatus === CONNECTION_REQUEST_STATUS.APPROVED
        return enableCheck
      }
      return true
    },
    selectType() {
      return this.$store.state.shop.selectType
    },
    selectCount() {
      return this.$store.state.shop.selectCount
    },
    isShop() {
      return this.selectType === MULTI_SELECT_PAGES.SHOP
    },
    hasDirectRetailers() {
      const userAssociation = this.$store.getters.getCurrentAssociation
      return this.isShop && (this.$root.$refs.shop.hasDirectRetailers || userAssociation?.entityType === ROLES.BRAND)
    },
    selectedProductsCount() {
      const shopState = this.$store.state.shop
      return shopState.selectCount
    },
  },
  methods: {
    moveAllToCartFromWishlist() {
      this.$root.$refs.wishlist.moveAllToCart()
      analytics.track(MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_ADD_TO_CART, { selectedProductsCount: this.selectCount, currentPage: this.selectType })
    },
    async removeAllFromWishList() {
      this.isRemovingFromWishlistProducts = true
      await this.$root.$refs.wishlist.removeAllFromWishList()
      this.isRemovingFromWishlistProducts = false
      analytics.track(this.isRetailer ? MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_REMOVE_FROM_WISHLIST : MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_REMOVE_FROM_SELECTION, { currentPage: this.selectType })
    },
    moveAllToWishListFromShop() {
      this.$root.$refs.shop.moveAllToWishList()
    },
    selectOrCreateWishlist() {
      this.$root.$refs.shop.selectOrCreateWishlist()
      analytics.track(this.isRetailer ? MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_ADD_TO_WISHLIST : MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_ADD_TO_SELECTION, { selectedProductsCount: this.selectCount, currentPage: this.selectType })
    },
    removeWishlistFromShop() {
      this.$root.$refs.shop.removeAllFromWishList()
      analytics.track(MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_REMOVE_FROM_WISHLIST, { currentPage: this.selectType })
    },
    addToCartFromShop() {
      this.$root.$refs.shop.moveAllToCart()
      analytics.track(MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_ADD_TO_CART, { selectedProductsCount: this.selectCount, currentPage: this.selectType })
    },
    addToOrder() {
      this.$root.$refs.shop.addToOrder()
      analytics.track(MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_ADD_TO_ORDER, { selectedProductsCount: this.selectCount, currentPage: this.selectType })
    },
    downloadSelectedProductsFromWishlist() {
      this.$root.$refs.wishlist.downloadSelectedWishlistProducts()
      analytics.track(MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_DOWNLOAD_PRODUCTS, { selectedProductsCount: this.selectCount, currentPage: this.selectType })
    },
    downloadSelectedProducts() {
      this.$root.$refs.shop.downloadSelectedProducts()
      analytics.track(MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_DOWNLOAD_PRODUCTS, { selectedProductsCount: this.selectCount, currentPage: this.selectType })
    },
    selectAll(select) {
      this.$store.commit(UPDATE_SELECT_COUNT, 0)
    if (this.isShop) {
      this.$root.$refs.shop.selectAll(select)
      analytics.track(MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_SELECT_ALL, { selectedProductsCount: this.selectCount, currentPage: this.selectType })
    } else {
       this.$root.$refs.wishlist.selectAll(select)
       analytics.track(MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_USER_CLICKS_DESELECT_ALL, { currentPage: this.selectType })
      }
    },
  },
}
</script>
<style lang="scss" >
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

.fontSelect{
    color: $black;
    font-weight: $font-weight-bold;
    font-size: $h2-font-size;
    margin-bottom: 0;
}
.select-action{
  margin-top: 4px;
  margin: 0 25px;
}
@media (max-width: 576px) {
  .fontSelect {
    font-size: $h5-font-size;
    margin-right: 0;
  }
  .select-span{
    margin-right: 0;
  }
  .select-action{
    margin: 0;
  }
}

.multi-select-ui-v2 {
  background: $body-color;
  animation: 0.5s ease-out 0s 1 slideOutFromBottom;
  box-shadow: 0px 15px 10px -15px #111;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  white-space: nowrap;

  .multi-select-action {
    position: relative;
    padding: 20px 20px;
    cursor: pointer;
      &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      height: 60%;
      width: 1px;
      background-color: #4C4C4C;
    }

    &:hover {
      background: #D9D9D9;
      color: $text-color-v2;
    }

    &:active {
      background: #D5DEF3;
      color: $text-color-v2;
    }
    &.products-count {
      min-width: 200px;
      cursor: unset;
      border-top-left-radius: 8px;

      &:hover {
        background: $text-color-v2;
        color: #D9D9D9;
      }
    }
  }
  .multi-select-action:last-child {
    border-top-right-radius: 8px;
    &::after {
      display: none;
    }
  }

  // Slide out from bottom
  @keyframes slideOutFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  // Slide in to bottom (for hiding)
  @keyframes slideInToBottom {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }
}

.multi-select-ui-v2.hide {
  animation: 0.5s ease-in 0s 1 slideInToBottom;
}
</style>
